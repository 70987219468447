<template>
  <main-layout pageTitle="History">
    <div class="d-flex">
      <div class="flex-grow-1 me-2">
        <ion-select v-if="seasons" v-model="currentSeasonId">
          <ion-select-option :value="null"> Current Season </ion-select-option>
          <ion-select-option
            v-for="season of seasons"
            :value="season.id"
            :key="season.id"
          >
            Season {{ formatSeason(season) }}
          </ion-select-option>
        </ion-select>
      </div>
      <ion-button class="me-3" expand="true" @click="gotoStats"
        >Stats</ion-button
      >
    </div>

    <past-tournament-list
      class="me-2"
      :showHeader="false"
      v-if="leagueId && seasons"
      :leagueId="leagueId"
      :seasonId="currentSeasonId"
    />
  </main-layout>
</template>

<script>
import PastTournamentList from "@/components/tournaments/PastTournamentList.vue";
import { IonSelect, IonSelectOption, IonButton } from "@ionic/vue";
import leagueModule from "../modules/leagues";
import axios from "axios";
import moment from "moment";

export default {
  components: { PastTournamentList, IonSelect, IonSelectOption, IonButton },
  data: () => ({
    leagueId: null,
    currentSeasonId: null,
    seasons: null,
  }),
  watch: {
    $route() {
      this.leagueId = this.$route.params.league_id;
    },
  },
  methods: {
    formatSeason(season) {
      return (
        moment(season.starts_at).year() +
        "-" +
        (moment(season.starts_at).year() + 1)
      );
    },

    gotoStats() {
      this.$router.push(
        "/league/" +
          this.leagueId +
          "/stats" +
          (this.currentSeasonId ? "/" + this.currentSeasonId : "")
      );
    },
  },
  async mounted() {
    this.leagueId = this.$route.params.league_id;
    if (this.$route.params.season_id) {
      this.currentSeasonId = parseFloat(this.$route.params.season_id);
    } else {
      this.currentSeasonId = null;
    }
    leagueModule.setCurrentLeague(this.leagueId);

    this.seasons = (await axios.get("seasons")).data;
  },
};
</script>
